<template>
  <b-row>
    <b-col cols="12">
      <b-form-group>
        <b-input-group>
          <b-form-datepicker
            v-model="filterParams.sdate"
            placeholder="Başlangıç Tarihi"
            v-bind="{labelNoDateSelected: 'Teslim Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
          <b-input-group-append is-text>
            -
          </b-input-group-append>
          <b-form-datepicker
            v-model="filterParams.edate"
            placeholder="Bitiş Tarihi"
            v-bind="{labelNoDateSelected: 'Teslim Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BFormDatepicker, BInputGroup, BInputGroupAppend, BFormGroup,
} from 'bootstrap-vue'

export default {
  name: 'IndexFilters',
  components: {
    BRow, BCol, BFormDatepicker, BInputGroup, BInputGroupAppend, BFormGroup,
  },
  computed: {
    filterParams() {
      return this.$store.getters['reportCustomers/filterParams']
    },
  },
}
</script>
