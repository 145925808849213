<template>
  <b-card no-body>
    <b-card-body>
      <index-filters />
    </b-card-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
      fixed
    >
      <template #cell(company)="data">
        <div class="d-flex align-items-center">
          <b-button
            variant="flat-primary"
            class="btn-icon mr-1"
            pill
            :to="$route.path + '/view/' + data.item.id"
          >
            <FeatherIcon icon="EyeIcon" />
          </b-button>
          <b-link
            :to="$route.path + '/view/' + data.item.id"
            class="text-body"
          >
            {{ data.item.company }}
          </b-link>
        </div>
      </template>
      <template #cell(supports)="data">
        <div>
          <div v-if="data.item.supports.duration_hours">
            {{ data.item.supports.duration_hours }} saat
          </div>
          <div v-else-if="data.item.supports.duration_minutes">
            {{ data.item.supports.duration_minutes }} dakika
          </div>
          <div v-else-if="data.item.supports.duration_seconds">
            {{ data.item.supports.duration_seconds }} saniye
          </div>
          <div v-else>
            -
          </div>
        </div>
      </template>
      <template #cell(tasks)="data">
        <div>
          <div v-if="data.item.tasks.duration_hours">
            {{ data.item.tasks.duration_hours }} saat
          </div>
          <div v-else-if="data.item.tasks.duration_minutes">
            {{ data.item.tasks.duration_minutes }} dakika
          </div>
          <div v-else-if="data.item.tasks.duration_seconds">
            {{ data.item.tasks.duration_seconds }} saniye
          </div>
          <div v-else>
            -
          </div>
        </div>
      </template>
      <template #cell(stopwatch_count)="data">
        <div>
          <div class="font-weight-bold font-medium-1 text-primary">
            <div v-if="data.item.duration_hours">
              {{ data.item.duration_hours }} saat
            </div>
            <div v-else-if="data.item.duration_minutes">
              {{ data.item.duration_minutes }} dakika
            </div>
            <div v-else-if="data.item.duration_seconds">
              {{ data.item.duration_seconds }} saniye
            </div>
            <div v-else>
              -
            </div>
          </div>
          <div class="font-small-3 font-weight-bold font-italic">
            {{ moment(data.item.started).format('DD.MM.YYYY') }} - {{ moment(data.item.ended).format('DD.MM.YYYY') }}
          </div>
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BCardFooter, BPagination, BTable, BButton, BLink,
} from 'bootstrap-vue'
import IndexFilters from '@/views/Admin/Reports/Customers/components/IndexFilters.vue'

export default {
  name: 'ReoortCustomers',
  components: {
    BPagination,
    BCardFooter,
    BCard,
    BCardBody,
    BTable,
    BButton,
    BLink,
    IndexFilters,
  },
  data() {
    return {
      dataCounts: 0,
      currentPage: 1,
      perPage: 20,
      fields: [
        {
          key: 'company',
          label: 'FİRMA ADI',
        },
        {
          key: 'supports',
          label: 'DESTEK',
          thClass: 'text-center width-200 text-nowrap',
          tdClass: 'text-center width-200 text-nowrap',
        },
        {
          key: 'tasks',
          label: 'GÖREV',
          thClass: 'text-center width-200 text-nowrap',
          tdClass: 'text-center width-200 text-nowrap',
        },
        {
          key: 'days',
          label: 'HİZMET / GÜN',
          thClass: 'text-center width-200 text-nowrap',
          tdClass: 'text-center width-200 text-nowrap',
        },
        {
          key: 'stopwatch_count',
          label: 'TOPLAM',
          thClass: 'text-center width-200 text-nowrap',
          tdClass: 'text-center width-200 text-nowrap',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['reportCustomers/dataList']
    },
    filterParams() {
      return this.$store.getters['reportCustomers/filterParams']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('reportCustomers/getDataList', {
        sdate: this.filterParams.sdate,
        edate: this.filterParams.edate,
      })
    },
  },
}
</script>
